import { ChevronRight } from 'lucide-react';
import { href, Link } from 'react-router';

export function DeliveryCheckerChip() {
  return (
    <div className='flex'>
      <div className='relative flex items-center gap-x-4 rounded-full px-4 py-1 text-xs leading-6 text-zinc-600 ring-1 ring-gray-900/10 hover:ring-zinc-900/20 dark:text-zinc-400 dark:ring-zinc-100/20 dark:hover:ring-zinc-100/30 xsm:text-sm xsm:leading-6'>
        <span className='font-semibold text-brand-600 dark:text-brand-500'>
          Delivery Checker
        </span>

        <span
          className='h-4 w-px bg-gray-900/10 dark:bg-zinc-100/20'
          aria-hidden='true'
        />

        <Link
          to={href('/order-now/check-delivery')}
          className='flex items-center gap-x-1'>
          <span className='absolute inset-0' aria-hidden='true' />
          Check my postcode
          <ChevronRight
            className='-mr-2 h-3 w-3 text-gray-400 dark:text-zinc-500'
            aria-hidden='true'
          />
        </Link>
      </div>
    </div>
  );
}
